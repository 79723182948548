<template>
  <el-dialog v-dialog-drag width="70%" :visible.sync="visible" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="first">
        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="合同标段" prop="contractName">
                <el-input v-model="dataForm.contractName" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关联分部分项" prop="wbsId">
                <wbs-select v-model="dataForm.wbsId" :contractId="dataForm.contractId" placeholder="选择分部分项" showPNames :leaf-only="false"></wbs-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查部位" prop="checkPos">
                <el-input v-model="dataForm.checkPos" placeholder="检查部位"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="安全检查项" prop="libraryId">
                <safety-library-select v-model="dataForm.libraryId" placeholder="选择安全检查项"></safety-library-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查人" prop="checker">
                <el-input v-model="dataForm.checker" placeholder="检查人"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查日期" prop="checkDate">
                <el-date-picker type="date" placeholder="检查日期" value-format="yyyy-MM-dd" v-model="dataForm.checkDate" style="width: 100%;"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查结果" prop="statusId">
                <el-select v-model="dataForm.statusId" placeholder="请选择" style="width: 100%;">
                  <el-option label="无需整改" :value="0"></el-option>
                  <el-option label="需要整改" :value="1"></el-option>
<!--                  <el-option label="已整改" :value="2"></el-option>-->
<!--                  <el-option label="已复查" :value="3"></el-option>-->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId == 1" :span="12">
              <el-form-item label="整改责任人" prop="rectifyId">
                <user-select v-model="dataForm.rectifyId" style="width: 100%"></user-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId == 1" :span="12">
              <el-form-item label="整改期限" prop="rectifyDeadline">
                <vxe-input v-model="dataForm.rectifyDeadline" class="unit-suffix" size="medium" :controls="false" placeholder="整改期限" type="integer" style="width: 100%;">
                  <template #suffix>
                    <span>天</span>
                  </template>
                </vxe-input>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId == 1" :span="12">
              <el-form-item label="复查责任人" prop="reviewId">
                <user-select v-model="dataForm.reviewId" style="width: 100%"></user-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="检查情况说明" prop="checkDesc">
                <el-input type="textarea" v-model="dataForm.checkDesc" :autosize="{ minRows: 3,maxRows:8}" placeholder="检查情况说明"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8" style="text-align: center;">
              <el-button style="margin-bottom: 5px" type="success" size="mini" icon="el-icon-map-location" @click="openPositionMap">选择坐标位置</el-button>
            </el-col>
            <el-col :span="8">
              <el-form-item label="经度" prop="longitude">
                <vxe-input v-model="dataForm.lon" placeholder="经度" size="medium" type="float" :digits="7" :controls="false" style="width: 100%;"></vxe-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="纬度" prop="latitude">
                <vxe-input v-model="dataForm.lat" placeholder="纬度" size="medium" type="float" :digits="7" :controls="false" style="width: 100%;"></vxe-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane v-if="this.dataForm.id" label="整改复查记录" name="second">
        <el-timeline>
          <el-timeline-item v-for="obj in clogList" :key="obj.id" :color="obj.color" :timestamp="obj.logDate + ' ' + obj.userName" placement="top">
            <el-card>
              <p>{{ obj.description }}</p>
              <h4 :style="{color: obj.color}">{{ obj.statusContent }} </h4>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </el-tab-pane>
    </el-tabs>
    <baidu-map-select v-if="positionMapVisible"
                      :longitude="dataForm.lon"
                      :latitude="dataForm.lat"
                      @close="closeMapDialogHandle" @setCoordinate="setCoordinate">
    </baidu-map-select>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import SafetyLibrarySelect from "@/components/safety-library-select";
import coordtransform from "coordtransform";

export default {
  data () {
    return {
      visible: false,
      activeName: 'first',
      dataForm: {
        id: '',
        contractId: '',
        checkPos: '',
        checker: '',
        libraryId: '',
        statusId: '',
        rectifyId: '',
        rectifyDeadline: '',
        reviewId: '',
        wbsId: '',
        checkDesc: '',
        lon: null,
        lat: null,
      },
      clogList: [],
      positionMapVisible: false
    }
  },
  components: {
    SafetyLibrarySelect
  },
  computed: {
    dataRule () {
      return {
        statusId: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        checkPos: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        checker: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        checkDate: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        rectifyId: [
          { required: this.dataForm.statusId == 1, message: this.$t('validate.required'), trigger: 'blur' }
        ],
        reviewId: [
          { required: this.dataForm.statusId == 1, message: this.$t('validate.required'), trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
          this.getClog()
        }
      })
    },
    openPositionMap() {
      let bd09List = this.wgs84tobd09(this.dataForm.lon, this.dataForm.lat)
      if (bd09List != null && bd09List.length > 0) {
        this.dataForm.lon = bd09List[0]
        this.dataForm.lat = bd09List[1]
      }
      this.positionMapVisible = true
    },
    // 接收地图弹窗中选择的坐标
    setCoordinate(data) {
      let wgs84List = this.bd09towgs84(data.lng, data.lat)
      if (wgs84List != null && wgs84List.length > 0) {
        this.dataForm.lon = wgs84List[0]
        this.dataForm.lat = wgs84List[1]
      }
    },
    closeMapDialogHandle() {
      this.positionMapVisible = false
    },
    // WGS84坐标转BD09坐标
    wgs84tobd09(lng, lat) {
      if (lng && lat) {
        let gcj02List = coordtransform.wgs84togcj02(lng, lat)
        return coordtransform.gcj02tobd09(gcj02List[0], gcj02List[1])
      } else {
        return null
      }
    },
    // BD09坐标转WGS84
    bd09towgs84(lng, lat) {
      if (lng && lat) {
        let gcj02List = coordtransform.bd09togcj02(lng, lat)
        return coordtransform.gcj02towgs84(gcj02List[0], gcj02List[1])
      } else {
        return null
      }
    },
    // 获取信息
    getInfo () {
      this.$http.get('/opm/safetyCheck/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
    // 查询整改复查记录
    getClog() {
      this.$http.get(
          '/opm/safetyClog/list',
          {
            params: {
              mainId: this.dataForm.id
            }
          }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.clogList = res.data
        this.clogList.forEach((obj) => {
          switch (obj.statusId) {
            case 2:
              obj.color = 'orange'
              obj.statusContent = '已整改'
              break;
            case 3:
              obj.color = 'green'
              obj.statusContent = '复查通过'
              break;
            case 4:
              obj.color = 'red'
              obj.statusContent = '复查未通过'
              break;
          }
        })
      }).catch(() => {})
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/opm/safetyCheck/', this.dataForm).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        }).catch(() => {})
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>