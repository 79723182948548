<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetycheck">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.checkPos" placeholder="检查部位" clearable ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.statusId" placeholder="检查状态" clearable @change="statusSelectChange">
            <el-option label="无需整改" :value="0"></el-option>
            <el-option label="整改中" :value="1"></el-option>
            <el-option label="已整改" :value="2"></el-option>
            <el-option label="已复查" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:safetycheck:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:safetycheck:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="checkPos" label="检查部位" min-width="200" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" @click="viewHandle(row.id)">{{row.checkPos}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="checker" label="检查人" width="150" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="checkDate" label="检查时间" width="120" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="statusId" label="检查状态" :formatter="statusFormatter" width="150" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="rectifyName" label="整改责任人" width="150" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="reviewName" label="复查责任人" width="150" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="120">
          <template v-slot="{row}">
<!--            <el-button v-if="$hasPermission('opm:safetycheck:update')" type="text" size="small" @click="addOrUpdateHandle(row.id)">{{ $t('update') }}</el-button>-->
            <table-button role="delete" v-if="$hasPermission('opm:safetycheck:update')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:safetycheck:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './safetycheck-add-or-update'
import ViewPage from './safetycheck-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        getDataListURL: '/opm/safetyCheck/page',
        getDataListIsPage: true,
        exportURL: '/opm/safetyCheck/export',
        deleteURL: '/opm/safetyCheck',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        checkPos: '',
        statusId: '',
      },
      contractName: '',
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换事件
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.contractName = data.contract.name
      this.getDataList()
    },
    // 查看
    viewHandle (id) {
      this.viewVisible = true
      this.$nextTick(() => {
        this.$refs.view.dataForm.id = id
        this.$refs.view.dataForm.contractId = this.dataForm.contractId
        this.$refs.view.dataForm.contractName = this.contractName
        this.$refs.view.init()
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.dataForm.contractName = this.contractName
        this.$refs.addOrUpdate.init()
      })
    },
    statusFormatter(row, column, cellValue) {
      switch (cellValue) {
        case 0:
          return "无需整改";
        case 1:
          return "整改中";
        case 2:
          return "已整改";
        case 3:
          return "已复查";
        default:
          return "";
      }
    },
    statusSelectChange() {
      this.getDataList()
    }
  }
}
</script>