<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="name" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" :placeholder="placeholder" size="medium" @click="showDropdown" :clearable="clearable && !readonly" @clear="onClear" readonly></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <div class="safety-library-select-dropdown">
        <vxe-table
            :loading="loading"
            height="auto"
            show-overflow
            :row-config="{isHover: true, useKey: true}"
            :column-config="{resizable: true}"
            :tree-config="treeConfig"
            :data="tableData"
            size="mini"
            @cell-click="cellClickEvent"
            :scroll-y="{enabled: false}">
          <vxe-column field="name" width="250" title="名称" tree-node></vxe-column>
          <vxe-column field="code" title="编号"></vxe-column>
        </vxe-table>
      </div>
    </template>
  </vxe-pulldown>
</template>
<script>
export default {
  name: 'SafetyLibrarySelect',
  data () {
    return {
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        line: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      tableData: [],
      name: '',
      loading: true,
      // 记录value的值，用于在watch value的值时判断是由于组件外部的修改（需要回显） 或是组件内部选择了某选项，双向绑定后导致的value变化（不需处理）
      localValue: '',
    }
  },
  created: function () {
    this.$http.get('/opm/safetyLibrary/list').then(({ data: res }) => {
      if (res.code !== 0) {
        return this.$message.error(res.msg)
      }
      this.tableData = res.data

    }).catch(() => {}).finally(() => {this.loading = false})
  },
  props: {
    value: String,
    placeholder: String,
    readonly: Boolean,
    // 默认不提供清空按钮，因为在目前后台系统的框架下，一个Long类型的字段在原本有值的情况下，无法将此字段清空并保存至数据库
    clearable: {
      type: Boolean,
      default: false
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    // 数据回显，分为单选和多选的情况，将绑定的value转换为级联选择组件所需的数据格式
    value: function (data) {
      if (data) {
        // 因组件内部选择了某选项，双向绑定后导致的value变化，所以不作处理
        if (data === this.localValue) {
          return
        }
        this.localValue = data
        this.$http.get('/opm/safetyLibrary/' + data).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.name = res.data.name
        }).catch(() => {})
      } else {
        this.name = ''
      }
    },
  },
  methods: {
    showDropdown() {
      if (!this.readonly) {
        this.$refs.xDown.showPanel()
      }
    },
    cellClickEvent({ row, triggerTreeNode }) {
      // 如果是折叠/展开树的操作，就不触发点击事件
      if (!triggerTreeNode) {
        this.name = row.name
        this.localValue = row.id
        this.$emit('change', row.id)
        this.$refs.xDown.hidePanel()
      }
    },
    loadChildren({ row }) {
      // 异步加载子节点
      return new Promise(resolve => {
        this.$http.get(
            '/opm/safetyLibrary/findChildren',
            {
              params: {
                pid: row.id,
                contractId: this.contractId
              }
            }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {})
      })
    },
    onClear() {
      this.localValue = ''
      this.$emit('change', '')
    }
  },
}
</script>
<style>
.safety-library-select-dropdown {
  /*width: 400px;*/
  height: 300px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}
</style>
