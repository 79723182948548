<template>
  <el-dialog v-dialog-drag top="5vh" width="70%" :visible.sync="visible" v-on="$listeners">
    <el-tabs>
      <el-tab-pane label="基本信息">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="small">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="合同标段" prop="contractId">
                <el-input v-model="dataForm.contractName" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="关联分部分项" prop="wbsId">
                <wbs-select v-model="dataForm.wbsId" :contractId="dataForm.contractId" showPNames disabled></wbs-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查部位" prop="checkPos">
                <el-input v-model="dataForm.checkPos" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查人" prop="checker">
                <el-input v-model="dataForm.checker" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查日期" prop="checkDate">
                <el-date-picker type="date" value-format="yyyy-MM-dd" v-model="dataForm.checkDate" style="width: 100%;" readonly></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检查状态" prop="statusId">
                <el-select v-model="dataForm.statusId" placeholder="" style="width: 100%;" disabled>
                  <el-option label="无需整改" :value="0"></el-option>
                  <el-option label="整改中" :value="1"></el-option>
                  <el-option label="已整改" :value="2"></el-option>
                  <el-option label="已复查" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId != 0" :span="12">
              <el-form-item label="整改责任人" prop="rectifyId">
                <user-select v-model="dataForm.rectifyId" style="width: 100%" disabled></user-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId != 0" :span="12">
              <el-form-item label="复查责任人" prop="reviewId">
                <user-select v-model="dataForm.reviewId" style="width: 100%" disabled></user-select>
              </el-form-item>
            </el-col>
            <el-col v-if="dataForm.statusId != 0" :span="12">
              <el-form-item label="整改期限" prop="rectifyDeadline">
                <vxe-input v-model="dataForm.rectifyDeadline" class="unit-suffix" size="medium" :controls="false" placeholder="整改期限" type="integer" readonly style="width: 100%;">
                  <template #suffix>
                    <span>天</span>
                  </template>
                </vxe-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="检查情况说明" prop="checkDesc">
                <el-input type="textarea" v-model="dataForm.checkDesc" :autosize="{ minRows: 3,maxRows:8}" placeholder="检查情况说明" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="经度" prop="lon">
                <vxe-input v-model="dataForm.lon" placeholder="经度" size="medium" type="float" :digits="7" :controls="false" readonly style="width: 100%;"></vxe-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="纬度" prop="lat">
                <vxe-input v-model="dataForm.lat" placeholder="纬度" size="medium" type="float" :digits="7" :controls="false" readonly style="width: 100%;"></vxe-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <my-upload-plain :refId="dataForm.id" view></my-upload-plain>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="整改复查记录">
        <el-timeline reverse>
          <el-timeline-item v-for="obj in clogList" :key="obj.id" :color="obj.color" :timestamp="obj.logDate + ' ' + obj.userName" placement="top">
            <el-card>
              <h4 :style="{color: obj.color}">{{ obj.statusContent }} </h4>
              <p>{{ obj.description }}</p>
              <el-image v-for="url in obj.imgUrls" fit="fill" :key="url" :src="url" :preview-src-list="obj.imgUrls" style="width: 60px; height: 60px;margin-left: 5px"></el-image>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-row v-if="dataForm.statusId == 0">
          <el-col :span="6">
            <el-alert title="无需整改" type="success" :closable="false"></el-alert>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        contractId: '',
        checkPos: '',
        checker: '',
        statusId: '',
        rectifyId: '',
        rectifyDeadline: '',
        reviewId: '',
        wbsId: '',
        checkDesc: '',
        lon: '',
        lat: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
      },
      clogList: [],
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
          this.getClog()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/opm/qualityCheck/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
      })
    },
    // 查询整改复查记录
    getClog() {
      this.$http.get(
          '/opm/qualityClog/list',
          {
            params: {
              mainId: this.dataForm.id
            }
          }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.clogList = res.data
        this.clogList.forEach((obj) => {
          switch (obj.statusId) {
            case 2:
              obj.color = 'orange'
              obj.statusContent = '已整改'
              break;
            case 3:
              obj.color = 'green'
              obj.statusContent = '复查通过'
              break;
            case 4:
              obj.color = 'red'
              obj.statusContent = '复查未通过'
              break;
          }
        })
        // 加载现场照片
        for (let i = 0; i < this.clogList.length; i++) {
          this.loadPictures(this.clogList[i])
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 加载现场照片
    loadPictures(obj) {
      this.$http.get('/oss/file/list/' + obj.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (res.data && res.data.length > 0) {
          let urls = []
          res.data.forEach((data) => {
            urls.push(data.url)
          });
          // 添加新属性，并刷新视图
          this.$set(obj, 'imgUrls', urls);
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
