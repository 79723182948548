<template>
  <div>
    <el-row :gutter="2">
      <el-col :span="8" v-for="(type, idx) in list" :key="type.typeId">
        <el-card>
          <div slot="header" class="header-blue">
            <i class="el-icon-receiving"></i><span> {{type.typeName}}</span>
          </div>
          <el-collapse accordion class="scrollbar-card" @change="changeHandle($event, idx)">
            <el-collapse-item v-for="help in type.helps" :key="help.id" :title="help.name" :name="help.id">
              <ul>
                <li v-for="oss in help.fileList" :key="oss.id">
                  <el-popover placement="right" width="154" trigger="hover">
                    <vue-qr :logoSrc="imageUrl" :text="oss.signedUrl" :size="qrSize"></vue-qr>
                    <el-link slot="reference" :underline="false" @click="downloadOss(oss)"><i class="el-icon-link"></i> {{ oss.name }}</el-link>
                  </el-popover>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
export default {
  data () {
    return {
      list: [],
      imageUrl: '',
      qrSize: 128
    }
  },
  created () {
    this.$nextTick(() => {
      this.getList()
    })
  },
  components: {
    vueQr
  },
  methods: {
    // 按分类分组查询帮助资料
    getList () {
      if (this.$hasPermission('sys:help:center')) {
        this.$http.get(`/sys/help/center`).then(({ data: res }) => {
          if (res.code === 0) {
            this.list = res.data
          }
        }).catch(() => {
          //
        })
      }
    },
    changeHandle (activeNames, index) {
      // activeNames 对应着帮助资料的ID
      if (activeNames) {
        let typeDto = this.list[index]
        if (typeDto) {
          this.$http.get(`/oss/file/list/${activeNames}`).then(({ data: res }) => {
            if (res.code === 0) {
              let helps = typeDto.helps
              if (helps != null && helps.length > 0) {
                for (let d of helps) {
                  if (d.id === activeNames) {
                    //d.fileList = res.data
                    if (res.data && res.data.length > 0) {
                      this.$set(d, 'fileList', res.data)
                    } else {
                      let fileList = [
                        {
                          id: 0,
                          name: '未上传'
                        }
                      ]
                      this.$set(d, 'fileList', fileList)
                    }
                  }
                }
              }
            }
          }).catch(() => {
            //
          })
        }
      }
    },
    // 鼠标点击在线打开或下载附件
    downloadOss (oss) {
      if (oss.id !== 0 && oss.signedUrl) {
        var a = document.createElement('a')
        var event = new MouseEvent('click')
        a.download = oss.name
        a.target = '_blank'
        a.href = oss.signedUrl
        a.dispatchEvent(event)
      }
    }
  }
}
</script>
<style scoped>
.el-card {
  cursor: pointer;
}
::v-deep .el-card .el-card__header {
  padding: 0;
  color: white;
}
.header-blue {
  background: #409EFF;
  padding: 5px;
}
.header-green {
  background: #67C23A;
  padding: 5px;
}
.header-brown {
  background: #E6A23C;
  padding: 5px;
}
.header-red {
  background: #F56C6C;
  padding: 5px;
}
::v-deep .span-danger {
  font-weight: bold;
  font-size: 16px;
  color: red;
}
::v-deep .span-warn {
  font-weight: bold;
  font-size: 16px;
  color: #E6A23C;
}
ul {
  padding: 0;
  margin: 0;
  text-align: left;
  list-style-type: none;
}
li {
  list-style-type: none;
  padding-top: 5px;
}
.grid-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100px;
}
.grid-con-icon-bg-blue {
  background: #2d8cf0;
}
.grid-con-icon-bg-green {
  background: #64d572;
}
.grid-con-icon-bg-orange {
  background: #f25e43;
}
.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}
.grid-cont-right {
  padding-left: 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  font-size: 16px;
  color: #999;
}
</style>